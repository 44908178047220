import styled from "styled-components";

import theme from "@Theme/theme";

const {
	media: { wdscreen },
	colors: { main },
} = theme;

const Styled = styled.div`
	.dropdown {
		.dropdown-menu {
			a {
				&[aria-current="page"] {
					@media ${wdscreen} {
						color: ${main} !important;
						box-shadow: none !important;
					}
				}
			}
		}
	}
`;

export default Styled;
