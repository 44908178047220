exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-about-page-index-js": () => import("./../../../src/templates/AboutPage/index.js" /* webpackChunkName: "component---src-templates-about-page-index-js" */),
  "component---src-templates-blog-page-index-js": () => import("./../../../src/templates/BlogPage/index.js" /* webpackChunkName: "component---src-templates-blog-page-index-js" */),
  "component---src-templates-blogs-list-page-index-js": () => import("./../../../src/templates/BlogsListPage/index.js" /* webpackChunkName: "component---src-templates-blogs-list-page-index-js" */),
  "component---src-templates-careers-page-index-js": () => import("./../../../src/templates/CareersPage/index.js" /* webpackChunkName: "component---src-templates-careers-page-index-js" */),
  "component---src-templates-case-studies-page-index-js": () => import("./../../../src/templates/CaseStudiesPage/index.js" /* webpackChunkName: "component---src-templates-case-studies-page-index-js" */),
  "component---src-templates-cases-list-page-index-js": () => import("./../../../src/templates/CasesListPage/index.js" /* webpackChunkName: "component---src-templates-cases-list-page-index-js" */),
  "component---src-templates-contact-us-page-index-js": () => import("./../../../src/templates/ContactUsPage/index.js" /* webpackChunkName: "component---src-templates-contact-us-page-index-js" */),
  "component---src-templates-services-page-index-js": () => import("./../../../src/templates/ServicesPage/index.js" /* webpackChunkName: "component---src-templates-services-page-index-js" */),
  "component---src-templates-single-service-page-index-js": () => import("./../../../src/templates/SingleServicePage/index.js" /* webpackChunkName: "component---src-templates-single-service-page-index-js" */)
}

