import React from "react";

import NavLink from "../NavLink";

const MobileMenu = ({ navs }) => (
	<div className="d-block d-xl-none">
		{navs.map((link, index) => (
			<NavLink key={index} link={link} />
		))}
	</div>
);

export default MobileMenu;
