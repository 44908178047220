import React from "react";
import { Dropdown } from "react-bootstrap";

import { Link } from "..";

const DropdownItem = ({ link, children, className, onClick }) => (
	<Dropdown.Item as={Link} link={link} onClick={onClick} className={className}>
		{children}
	</Dropdown.Item>
);

export default DropdownItem;
