import { useStaticQuery, graphql } from "gatsby";

export const useTopMenu = () => {
	const { contentfulTopMenu } = useStaticQuery(
		graphql`
			query {
				contentfulTopMenu {
					navs {
						id
						navLink
						navName
					}
				}
			}
		`
	);
	return contentfulTopMenu;
};
