import React from "react";

import Styled from "./styled.js";
import logo from "@Images/svg/VTL-logo-trassered.svg";

const Logo = () => {
	return (
		<Styled>
			<img src={logo} alt="VT Labs" />
		</Styled>
	);
};

export default Logo;
