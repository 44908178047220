import styled from "styled-components";

const Styled = styled.div`
	.navbar-toggler {
		border: none;
		width: 32px;
		height: 25px;
		position: relative;
		top: -5px;
		right: 0;
		&:active,
		&:focus {
			outline: 0;
		}
		.navbar-toggler-icon,
		&:after,
		&:before {
			content: "";
			display: block;
			width: 100%;
			height: 3px;
			background: ${(props) => props.theme.colors.white};
			position: absolute;
			top: 10px;
			left: 0;
			transition: all 0.6s;
			z-index: 1;
		}
		.navbar-toggler-icon {
			opacity: 0;
		}
		&:before {
			transform: rotate(-45deg);
		}
		&:after {
			transform: rotate(45deg);
		}
		&.collapsed {
			.navbar-toggler-icon,
			&:after,
			&:before {
				background: ${(props) => props.theme.colors.second};
			}
			&:before {
				top: 10px;
				transform: rotate(0);
			}
			&:after {
				top: 20px;
				transform: rotate(0);
			}
			.navbar-toggler-icon {
				opacity: 1;
				top: 0;
			}
		}
	}
`;

export default Styled;
