import styled from "styled-components";

import theme from "@Theme/theme";

const {
	colors: { main },
} = theme;

export const StyledOl = styled.ol`
	li p {
		margin-bottom: 0;
	}
`;

export const StyledUl = styled.ul`
	li p {
		margin-bottom: 0;
	}
`;

export const StyledColoredText = styled.span`
	text-align: ${({ textAlign }) => textAlign};
	color: ${({ textColor }) => textColor} !important;
	font-size: ${({ fontSize }) => fontSize}px !important;
	a :hover {
		color: ${main} !important;
	}
`;

export const StyledPre = styled.pre`
	padding: 2rem;
	line-height: 1.9;
	overflow: scroll;
	border-radius: 4px;
	overflow-y: hidden;
	background-color: #e8e8e8;
`;
