import React from "react";
import { Button } from "react-bootstrap";

import { Link } from "..";

const ButtonLink = ({
	link,
	onClick,
	children,
	className,
	size = "",
	variant = "primary",
}) => (
	<Button
		as={Link}
		link={link}
		size={size}
		variant={variant}
		onClick={onClick}
		className={className}
	>
		{children}
	</Button>
);

export default ButtonLink;
