import styled from "styled-components";

const Styled = styled.div`
	.container {
		max-width: 1380px;
	}
	.nav {
		@media ${(props) => props.theme.media.tablet} {
			font-size: 14px;
			font-weight: 600;
			text-transform: uppercase;
			flex-direction: column;
			align-items: flex-start;
			min-width: 211px;
		}
		a {
			@media ${(props) => props.theme.media.tablet} {
				transition: box-shadow
					${(props) => props.theme.animations.transition_base};
				color: ${(props) => props.theme.colors.gray_500};
				padding: 12px 18px;
				display: inline-block;
				border-radius: ${(props) => props.theme.bradius.bradius_md};
			}
			&:hover {
				@media ${(props) => props.theme.media.tablet} {
					box-shadow: ${(props) => props.theme.shadows.shadow_inset_md};
				}
			}
		}
	}
`;

export default Styled;
