import React from "react";
import { ThemeProvider } from "styled-components";

import ContextProvider from "../provider/ContextProvider";
import Navbar from "@Components/Header";
import Footer from "@Components/Footer";
import Theme from "../theme/theme";
import GlobalStyles from "../theme/global";

const Base = ({ children }) => (
	<ContextProvider>
		<ThemeProvider theme={Theme}>
			<GlobalStyles />
			<div id="wrapper">
				<Navbar />
				<main>{children}</main>
				<Footer />
			</div>
		</ThemeProvider>
	</ContextProvider>
);

export default Base;
