import styled from "styled-components";

import theme from "@Theme/theme";
import { rotateCircle } from "@Theme/global";

const {
	media: { tablet, desktop },
	colors: { white, gray_100 },
	shadows: { shadow_inset_md, shadow_outer_md },
} = theme;

const ImageWrapper = styled.div`
	width: 140px;
	height: 140px;
	padding: 5px;
	border-radius: 50%;
	position: relative;
	margin: 6px auto 26px;
	background: ${gray_100};
	border: 4px solid ${white};
	box-shadow: ${shadow_outer_md};
	@media ${tablet} {
		width: 186px;
		height: 186px;
		margin-bottom: 38px;
		box-shadow: ${shadow_inset_md};
	}
	&:after {
		@media ${desktop} {
			content: "";
			position: absolute;
			border-radius: 50%;
			top: -11px;
			left: calc(44.5% - 11px);
			width: 22px;
			height: 22px;
			transform-origin: 100% 100px;
			background-color: red;
			animation: ${rotateCircle} 8s linear infinite;
			animation-play-state: paused;
			border: 4px solid ${white};
		}
	}
`;

const Image = styled.img`
	max-width: 100%;
	@media ${desktop} {
		filter: grayscale(100%);
		transition: filter 3s;
	}
`;

const Styled = styled.div`
	&:hover {
		${ImageWrapper} {
			&:after {
				@media ${desktop} {
					animation-play-state: running;
				}
			}
			${Image} {
				@media ${desktop} {
					filter: grayscale(0%);
				}
			}
		}
	}
`;

export { ImageWrapper, Image };

export default Styled;
