import styled from "styled-components";

import theme from "@Theme/theme";

const {
	space: { default_space },
} = theme;

const buildSpace = (space = 0) => `${space * default_space}px`;

const Styled = styled.div`
	display: ${({ display = "block" }) => display};
	padding-top: ${({ pt }) => buildSpace(pt)};
	padding-bottom: ${({ pb }) => buildSpace(pb)};
	padding-left: ${({ pl }) => buildSpace(pl)};
	padding-right: ${({ pr }) => buildSpace(pr)};
	margin-top: ${({ mt }) => buildSpace(mt)};
	margin-bottom: ${({ mb }) => buildSpace(mb)};
	margin-left: ${({ ml }) => buildSpace(ml)};
	margin-right: ${({ mr }) => buildSpace(mr)};
`;

export default Styled;
