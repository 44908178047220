import React from "react";

import { StyledVideoWrapper } from "./styled";

const Video = ({ data }) => {
	const { youTubeTitle = "youTubeDefaultTitle", youTubeContentId } = data;
	if (!youTubeContentId) {
		return null;
	}

	return (
		<StyledVideoWrapper>
			<iframe
				src={`https://www.youtube.com/embed/${youTubeContentId}`}
				title={youTubeTitle}
				allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
				frameBorder="0"
				webkitallowfullscreen="true"
				mozallowfullscreen="true"
				allowFullScreen
			/>
		</StyledVideoWrapper>
	);
};
export default Video;
