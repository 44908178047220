import styled, { createGlobalStyle, keyframes } from "styled-components";
import Img from "gatsby-image";

import "./vendors/fonts.scss";
import "./vendors/custom_bootstrap.scss";

import theme from "@Theme/theme";
import breadcrumbArrow from "@Images/svg/breadcrumb-arrow.svg";

const {
	gradients: { gradient_100 },
	animations: { transition_base },
	BREAKPOINTS: { TABLET, WDSCREEN },
	bradius: { bradius_sm, bradius_md },
	media: { desktop, wdscreen, tablet, maxDesktop },
	shadows: { shadow_outer_md, shadow_inset_md, shadow_outer_second },
	colors: { main, gray_900, gray_100, white, gray_300, gray_250, second },
} = theme;

const GlobalStyles = createGlobalStyle`
	#wrapper {
		position: relative;
		height: 100%;
	}

	html, body {
		height: 100%;
		font-size: 16px;
		@media ${desktop} {
			font-size: 18px;
		}
	}

	body {
		background-color: ${gray_100};
		&.navbar-open {
			overflow: hidden;
		}
	}

	main {
		padding-top: 80px;
	}

	a {
		transition: color ${transition_base};
	}

	// Images
	.img-full {
		min-width: 100%;
	}

	//Buttons
	.btn {
		font-weight: 500;
		border-color: ${white};
		padding: 15px 23px 14px;
		text-transform: capitalize;
		transition: color border-color ${transition_base};
		@media ${maxDesktop} {
			transition: none;
		}
		&.btn-primary {
			color: ${main};
			box-shadow: ${shadow_outer_md};
			background: ${gradient_100};
			background-size: 100%;
			z-index: 100;
			overflow: hidden;
			position: relative;
			font-weight: 500;
			&:before {
				content: "";
				display: block;
				height: 100%;
				position: absolute;
				top: 0; left: 0; right: 0; bottom: 0;
				opacity: 0;
				width: 100%;
				z-index: -100;
				border-radius: 11px;
				transition: opacity ${transition_base};
				@media ${maxDesktop} {
					transition: none;
				}
			}
			&:hover,
			&:focus,
			&:active,
			&:active:focus {
				color: ${white};				
				border: 1px solid ${gray_300};		
				background: ${main};
				&:before {
					opacity: 1;
				}
			}
		}
		&.btn-secondary {
			box-shadow: ${shadow_outer_md};
		}
		&.btn-second {
			color: ${main};
			border: none;
			box-shadow: ${shadow_outer_second};
			background: ${second};
			&:hover,
			&:active,
			&:active:focus {
				color: ${white};
				background: ${main};
			}
		}
		&.btn-danger {
			color: ${white};
			transition: background ${transition_base};
			border-color: ${main};
			@media ${maxDesktop} {
				transition: none;
			}
			&:hover,
			&:focus,
			&:active,
			&:active:focus 
			{
				background: ${white};
				color: ${main};
			}
			&.btn-more {
				color: ${white};
				border: none;
			}
		}
		&.btn-link {
			border: none;
			&:hover {
				box-shadow: ${shadow_inset_md};
			}
		}
		&.btn-more {
			font-size: 16px;
			text-transform: none;
			line-height: 1.2;
			border-color: ${gray_300};
			color: ${gray_900};;
		}
		&.btn-sm {
			padding: 11px 28px 11px;
		}
	}
	.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active:focus{
		border-color: ${white};
		box-shadow: ${shadow_outer_md};
	}

	// Form
	form {
		@media ${tablet} {
			padding-right: 0;
		}
	}
	.form-row {
		@media ${desktop} {
			margin: 0 -34px;
		}
		> [class*=col-] {
			@media ${desktop} {
				padding: 0 34px;
			}
		}
	}
	input[type='text'],
	input[type='tel'],
	input[type='email'],
	input[type='search'],
	input[type='password'],
	input[type='url'],
	input[type='date'],
	textarea,
	.form-control {
		border: 2px solid transparent;
		box-shadow: ${shadow_inset_md};
		background-color: ${gray_250};
		height: 54px;
		padding: 15px 14px;
		font-size: 14px;
		@media ${tablet} {
			font-size: 16px;
			height: 52px;
			padding: 10px 22px;
		}
		&:focus {
			border: 2px solid ${main};
			background-color: ${gray_250};
			box-shadow: ${shadow_inset_md};
			outline: 0;
		}
	}
	textarea.form-control {
		padding: 21px 24px;
		min-height: 218px;
	}
	.invalid-feedback {
		color: ${main};
		padding-left: 16px;
		padding-top: 5px;
		font-size: 14px;
	}
	.was-validated .form-control:valid, .form-control.is-valid {
		border-color: transparent;
		background-image: none;
	}
	.was-validated .form-control:invalid, .form-control.is-invalid {
		background-image: none;
	}
	.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
		color: ${gray_900};;
	}
	.custom-checkbox {
		&.custom-control {
			padding-left: 0;
		}
		.custom-control-label {
			margin-bottom: 15px;
			padding-left: 54px;
			padding-top: 5px;
			min-height: 35px;
			font-weight: 500;
			font-size: 16px;
			cursor: pointer;
			@media ${tablet} {
				font-size: 18px;
			}
			&:before {
				border: none;
				height: 35px;
				width: 35px;
				background: ${gray_250};
				border-radius: ${bradius_sm};
				box-shadow: ${shadow_outer_md};
				position: absolute;
				top: 0;
				left: 0;
				transition: background ${transition_base},
							box-shadow ${transition_base};
			}
			&:after {
				background-image: none !important;
				background: ${main};
				border-radius: 4px;
				opacity: 0;
				position: absolute;
				left: 10px;
				top: 10px;
				height: 16px;
				width: 16px;
				transition: opacity ${transition_base};
			}
		}
		.custom-control-input:checked ~ .custom-control-label {
			&:before {
				background: ${gray_250};
				box-shadow: ${shadow_inset_md};
				transition: all ${transition_base};
			}
			&:after {
				opacity: 1;
			}
		}
	}
	.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
		background: ${gray_250};
		border-radius: ${bradius_sm};
	}
	.custom-control {
		&.custom-switch {
			padding: 0;
			.custom-control-label {
				height: 36px;
				padding-top: 0;
				cursor: pointer;
				font-size: 14px;
				line-height: 1.3;
				position: relative;
				padding-left: 90px;
				vertical-align: middle;
    		display: table-cell;
				@media ${tablet} {
					line-height: 1.6;
					font-size: 18px;
				}
				&:before {
					background: ${gray_250};
					box-shadow: ${shadow_inset_md};
					border: none;
					width: 72px;
					height: 36px;
					border-radius: 50px;
					transition: all ${transition_base};
					position: absolute;
					left: 0;
					top: 0;
				}
				&:after {
					width: 20px;
					height: 20px;
					top: 8px;
					left: 6px;
					background: ${white};
					border-radius: 50%;
					transition: all ${transition_base};
					box-shadow: ${shadow_outer_md};
				}
			}
			.custom-control-input:checked ~ .custom-control-label {
				&:before {
					background: ${gray_250};
				}
				&::after {
					background: ${main};
					left: 31px;
				}
			}
		}
	}
	.custom-control.custom-switch .custom-control-input:checked ~ .custom-control-label:before {
		border-radius: 50px;
		background: ${gray_250};
		box-shadow: ${shadow_inset_md};	
	}
	
	//Modal 
	.modal {
		z-index: 10000;
		text-align: center;
		font-size: 18px;
		line-height: 1.3;
		font-weight: 300;
		display: flex !important;
		flex-direction: column;
		padding: 20px 0;
		@media ${tablet} {
			font-size: 24px;
			line-height: 1.454;
			justify-content: center;
		}
		.modal-dialog {
			width: calc(100% - 40px);
			max-width: 1064px;
			margin: 0 auto;
		}
		.modal-content {
			.text-lead {
				color: ${main};
				font-weight: 500;
				margin-top: 20px;
			}
		}
		.text-holder {
			max-width: 736px;
			margin: 0 auto;

			p:first-child {
				max-width: 652px;
				margin: 0 auto;
			}
		}
		.modal-body {
			padding: 10px 1rem;
			@media ${tablet} {
				padding: 35px 1rem;
			}
		}
		a {
			color: ${white};
		}
		.modal-title {
			color: ${white};
			font-weight: 500;
			line-height: 1.3;
			margin-bottom: 15px;
			@media ${tablet} {
				margin-bottom: 20px;
			}
		}
		.modal-header {
			.close {
				text-indent: -111111px;
				position: absolute;
				right: 27px;
				top: 36px;
				padding: 0;
				margin: 0;
				height: 22px;
				width: 30px;
				@media ${tablet} {
					height: 20px;
					top: 46px;
					width: 40px;
				}
				&:hover {
					&:after, &:before {
						background: ${main};
					}
				}
				&:after, &:before {
					content: '';
					display: block;
					width: 100%;
					height: 2px;
					background: ${gray_300};
					position: absolute;
					transition: background ${transition_base};
					z-index: 1;
					top: 10px; left: 0;
					@media ${tablet} {
						height: 4px;
						top: 0;
					}
				}
				&:before {
					transform: rotate(-45deg);
				}
				&:after {
					transform: rotate(45deg);
				}
			}
		}
	}

	.inner-page {
		.container.narrow-container{
			max-width: 80% !important;
			@media (min-width: ${WDSCREEN}px) {
				max-width: 70% !important;
			}
			@media (max-width: ${TABLET}px) {        
				max-width: 100% !important;
			}
		}		
		.controls-details {
			.category {
				text-transform: uppercase;
				font-weight: 600;
				font-size: 12px;
				display: block;
				margin-bottom: 4px;
				@media ${tablet} {
					margin-bottom: 0;
					margin-right: 40px;
				}
			}
		}
	}
	.inner-content {
		h3 {
			color: ${gray_900};;
			margin-bottom: 30px;
		}
		p + h3 {
			margin-top: 80px;
		}
		p + img {
			margin-top: 50px;
		}
		img {
			margin-bottom: 50px;
			width: 100%;
		}
	}

	//Sidebar
	.sidebar {
		margin-bottom: 60px;
		width: 100%;
		@media ${tablet} {
			margin-bottom: 20px;
		}
	}
	.sidebar-content {
		font-size: 14px;
		margin-top: 50px;
		line-height: 1.57;
		margin-bottom: 50px;
		@media ${tablet} {
			width: 100%;
			margin-top: 0;
			font-size: 16px;
			line-height: 1.5;
			margin-bottom: 70px;
		}
		@media ${desktop} {
			margin-bottom: 100px;
		}
		h3 {
			font-size: 22px;
			color: ${gray_900};
			margin-bottom: 30px;
			@media ${tablet} {
				font-size: 32px;
			}
		}
		h2, h3 {
			color: ${gray_900};		
		}
		p + h3 {
			margin-top: 60px;
		}
	}

	//Icons
	.icon-circle {
		display: block;
		width: 21px;
		height: 21px;
		background: ${main};
		margin: 77px auto 20px auto;
		border: 2px solid ${white};
		box-shadow: 0 0 0 12px rgba(206,211,220, .2);
		border-radius: 50%;
	}

	//Progress Bar
	.progress {
		border-radius: 0;
		background: #C7C7C7;
		height: 4px;
	}

	// Slick Slider
	.slick-slider {
		@media ${wdscreen} {
			padding-bottom: 0;
		}
	}
	.slick-dots {
		margin: 0;
		padding: 0;
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		list-style: none;
		padding-top: 18px;
		position: relative;
		text-align: center;
		align-items: center;
		justify-content: center;
		li {
			margin: 6px 8px 0px;
			overflow: hidden;
			width: 9px;
			height: 9px;
			display: inline-block;
			&.slick-active,
			&:hover {
				button:before {
					background: ${main};
				}
			}
		}
		button {
			position: relative;
			font-size: 0;
			line-height: 0;
			display: block;
			cursor: pointer;
			color: transparent;
			border: 0;
			outline: none;
			background: transparent;
			width: 9px;
			height: 9px;
			padding: 0;
			&:before {
				content: '';
				transition: ${transition_base};
				position: absolute;
				outline: 0;
				background: ${gray_300};
				border-radius: 50%;
				width: 9px;
				height: 9px;
				top: 0;
				left: 0;
				-webkit-font-smoothing: antialiased;
			}
		}
	}
	.line-dashed {
		border-top: 2px dashed ${white};
		width: 100%;
		height: 2px;
	}
	.slick-sameheight {
		.slick-track { display: flex !important; } .slick-slide { height: inherit !important; 	display: flex !important; 	justify-content: center; 	align-items: center; }
		.slick-slide > div { height: 100%; display: flex;}
	}

	// Breadcrumb
	.breadcrumb {
		background-color: transparent;
		text-transform: uppercase;
		color: ${gray_900};;
		font-weight: 600;
		margin-top: 8px;
		display: block;
		@media ${tablet} {
			margin-top: 27px;
		}
		a {
			color: ${gray_900};;
			&:hover {
				color: ${main};
			}
		}
		.breadcrumb-item {
			word-break: break-word;
    		position: relative;
			& + .breadcrumb-item {
				padding-left: 1.6rem;
				::before {
					content: "";
					position: absolute;
					width: 12px;
					height: 12px;
					left: 9px;
					top: 1px;
					background-repeat:no-repeat;
					background-image: url(${breadcrumbArrow});
					@media ${tablet} {
						top: 2px;
					}
				}
			}
			&.active {
				color: ${gray_900};;
			}
		}
	}

	// Boxes
	.theme-box {
		font-size: 14px;
		margin-top: 35px;
		line-height: 1.57;
		margin-bottom: 50px;
		@media ${tablet} {
			font-size: 16px;
			line-height: 1.5;
			margin-top: 70px;
			margin-bottom: 70px;
			background: ${gray_100};
			border-radius: ${bradius_md};
			padding: 53px 82px 113px 82px;
			box-shadow: ${shadow_outer_md};
		}
		@media ${desktop} {
			padding: 53px 82px 90px 82px;
			margin-top: 100px;
			margin-bottom: 100px;
		}
		.img-holder {
			height: fit-content;
			margin-bottom: 24px;
			@media ${tablet} {
				margin-bottom: 44px;
			}
			img {
				object-fit: cover;
			}
		}
	}

	article {
		font-size: 14px;
		line-height: 1.57;
		@media ${tablet} {
			font-size: 16px;
			line-height: 1.5;
		}
		.h4 {
			color: ${gray_900};;
			font-weight: 600;
			font-size: 18px;
			margin-bottom: 22px;
			@media ${tablet} {
				font-size: 32px;
			}
		}
		a {
			text-decoration: underline;
			color: ${gray_900};;
		}
		.row {
			@media ${tablet} {
				margin: 0 -40px;
			}
		}
		[class*="col-"] {
			@media ${tablet} {
				padding: 0 40px;
			}
		}
		ol {
			padding-left: 16px;
		}
		ul {
			padding-left: 16px;
		}
	}
	// Blockquote
	.blockquote {
		font-size: 14px;
		text-align: center;
		line-height: 1.428;
		font-weight: 600;
		font-style: italic;
		margin-bottom: 42px;
		margin-top: 35px;
		@media ${tablet} {
			font-size: 16px;
			line-height: 1.75;
			margin-bottom: 64px;
		}
		svg {
			color: ${main};
			margin-bottom: 17px;
			height: 20px;
			@media ${tablet} {
				margin-bottom: 8px;
			}
		}
	}

	//Slices
	.slice-wrapper {
		padding-top: 50px;
		padding-bottom: 50px;
		@media ${tablet} {
			padding-top: 70px;
			padding-bottom: 70px;
		}
		@media ${desktop} {
			padding-top: 100px;
			padding-bottom: 100px;
		}
		> :last-child {
    	margin-bottom: 0;
		}
		.block-title {
			margin: 0 auto 60px;
			@media ${tablet} {
				margin: 0 auto 70px;
			}
			@media ${desktop} {
				margin: 0 auto 100px;
			}
			.h2,
			h2 {
				margin: 0 auto 30px;
			}
		}
	}

	// Divider
	.divider {
		height: 1px;
		border: none;
		margin: 70px 0px;		
		background: ${gray_300};
	}

	hr {
		margin-top: 0;
  	margin-bottom: 0;
	}

	.hide {
		display: none;
	}

	.rounded {
		img {
			border-radius: 12px;
		}
	}
`;
export default GlobalStyles;

export const GatsbyImg = styled(Img)`
	img {
		border-radius: 12px !important;
	}
`;

export const fadeIn = keyframes`
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
`;
export const rotateCircle = keyframes`
	0% {
		transform: rotate(45deg);
	}
	100% {
		transform: rotate(405deg);
	}
`;
