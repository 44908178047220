import React from "react";
import { Form } from "react-bootstrap";

const FormCheck = (props) => {
	// TODO: Move Check Styles to Styled Components;
	const { switchRef } = props;
	return (
		<Form.Group>
			<Form.Check required ref={switchRef} {...props} type="switch" />
		</Form.Group>
	);
};

export default FormCheck;
