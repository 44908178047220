import styled from "styled-components";

import theme from "@Theme/theme";

const {
	media: { tablet },
} = theme;

export const HideMobile = styled.div`
	display: none;
	@media ${tablet} {
		display: block;
	}
`;
