import React from "react";
import PropTypes from "prop-types";

import NotFound from "./NotFound";
import Base from "./Base";
import ContactUs from "./ContactUs";

const Layout = ({ children, pageContext }) => {
	if (pageContext?.layout === "contact-us") {
		return <ContactUs>{children}</ContactUs>;
	}
	if (pageContext?.layout === "404") {
		return <NotFound>{children}</NotFound>;
	}

	return <Base>{children}</Base>;
};

Layout.propTypes = {
	children: PropTypes.node.isRequired,
};

export default Layout;
