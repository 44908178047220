import styled from "styled-components";

const Styled = styled.div`
	position: absolute;
	width: 100%;
	top: 0;
	left: 0;
	right: 0;
	z-index: -1;
	overflow: hidden;
	min-height: 100%;
`;

const Video = styled.video`
	position: relative;
	min-width: 100%;
	height: 100%;
	left: 50%;
	transform: translate(-50%, 0);
	object-fit: cover;
	visibility: visible !important;
`;

const Overlay = styled.div`
	position: absolute;
	bottom: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
	box-shadow: inset 0px 1px 40px 50px rgba(241, 241, 241, 1);
`;

export { Video, Overlay };
export default Styled;
