import styled from "styled-components";

import theme from "@Theme/theme";

const {
	media: { tablet },
} = theme;

const Styled = styled.ul`
	text-align: center;
	margin-bottom: 57px;
	@media ${tablet} {
		text-align: left;
		margin-bottom: 0;
	}
	a {
		display: inline-block;
		margin-bottom: 10px;
		img {
			width: 100%;
		}
	}
`;

export default Styled;
