import styled from "styled-components";

import theme from "@Theme/theme";

const {
	media: { tablet },
	space: { space_sm },
	colors: { gray_900 },
	font_size: { h1_desktop, h1_mobile },
	font_weight: { bold },
	font_family: { primary },
	line_height: { heading },
} = theme;

const H1 = styled.h1`
	font-family: ${primary};
	line-height: ${heading};
	font-size: ${h1_mobile};
	text-transform: uppercase;
	font-weight: ${bold};
	color: ${gray_900};
	margin-bottom: ${space_sm};
	@media ${tablet} {
		font-size: ${h1_desktop};
	}
`;

export default H1;
