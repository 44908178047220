import styled from "styled-components";

import theme from "@Theme/theme";

const {
	media: { desktop, tablet },
	space: { space_md, space_lg, space_xl },
} = theme;

const Styled = styled.div`
	padding-top: ${space_md};
	padding-bottom: ${space_md};
	@media ${tablet} {
		padding-top: ${space_lg};
		padding-bottom: ${space_lg};
	}
	@media ${desktop} {
		padding-top: ${space_xl};
		padding-bottom: ${space_xl};
	}
`;

const SectionHeading = styled.div`
	margin: 0 auto ${space_md};
	@media ${tablet} {
		margin: 0 auto ${space_lg};
	}
`;

export { SectionHeading };

export default Styled;
