import React from "react";
import { isEmpty } from "lodash";

import Styled from "./styled";
import SocialItem from "./SocialItem";

const Socials = ({ socials }) => {
	if (isEmpty(socials)) {
		return null;
	}

	return (
		<Styled>
			<ul className="list-unstyled d-flex">
				{socials.map((socialItem) => (
					<SocialItem key={socialItem.id} socialItem={socialItem} />
				))}
			</ul>
		</Styled>
	);
};

export default Socials;
