import React from "react";
import { isEmpty } from "lodash";
import { GatsbyImage } from "gatsby-plugin-image";

const Image = ({
	src,
	description,
	isShowDescription,
	fetchpriority,
	alt = "",
	loading = "lazy",
	className = "",
	fadeIn = true,
}) => {
	if (isEmpty(src)) {
		return null;
	}
	return (
		<>
			<GatsbyImage
				fetchpriority={fetchpriority}
				loading={loading}
				alt={alt}
				image={src}
				className={className}
				fadeIn={fadeIn}
			/>
			{isShowDescription && <p className="image-description">{description}</p>}
		</>
	);
};

export default Image;
