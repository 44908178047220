import React from "react";
import Styled, { ImageWrapper, Image } from "./styled";
import { Spacer, Text, Align, LeadText } from "@Components/ui";

const Item = ({ title, text, imageUrl, imageAlt }) => {
	return (
		<Styled>
			<Spacer mb={2}>
				<ImageWrapper>
					<Image src={imageUrl} alt={imageAlt} />
				</ImageWrapper>
				<Spacer ml={1} mr={1}>
					<Align direction="center">
						{title && (
							<h3>
								<LeadText>{title}</LeadText>
							</h3>
						)}
						<Text>{text}</Text>
					</Align>
				</Spacer>
			</Spacer>
		</Styled>
	);
};

export default Item;
