import React, { useContext } from "react";
import { Dropdown } from "react-bootstrap";

import { Link } from "@Components/ui";
import { getFormattedURL } from "@Utils/utils.js";
import StoreContext from "@Context/StoreContext";

const NavLink = ({ link: { id, navName, navLink } }) => {
	const { closeMobileMenu } = useContext(StoreContext);
	const formattedLink = getFormattedURL(navLink);
	return (
		<Dropdown key={id}>
			<Link
				linkType="internal"
				link={formattedLink}
				className="dropdown-link"
				onClick={closeMobileMenu}
			>
				{navName}
			</Link>
		</Dropdown>
	);
};

export default NavLink;
