import styled from "styled-components";

const Styled = styled.div`
	a {
		display: block;
		width: 143px;
		height: 64px;
	}
`;

export default Styled;
