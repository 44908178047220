import { Button } from "react-bootstrap";
import styled from "styled-components";

// TODO: Move All Button Styles to Styled Components;

const Styled = styled(Button)`
	&:disabled {
		color: grey;
		border-color: #f2f2f2;
		:hover {
			border-color: #f8f8f8;
			background: #f8f8f8;
			color: grey;
			box-shadow: -3px -3px 6px 0px rgb(255 255 255 / 20%),
				3px 3px 8px 0px rgb(0 0 0 / 20%);
		}
	}
`;

export default Styled;
