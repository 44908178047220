import styled from "styled-components";

import theme from "@Theme/theme";

const {
	media: { desktop, lgscreen },
	colors: { main, second },
	shadows: { shadow_inv_outer_md },
} = theme;

const Styled = styled.div`
	@media ${desktop} {
		margin-top: -2px;
		max-width: 300px;
	}
	@media ${lgscreen} {
		margin-left: 10px;
	}
	.btn.btn-primary {
		font-size: 18px;
		@media (max-width: 1199px) {
			margin-top: 30px;
			border-color: ${second};
			background: ${second};
			box-shadow: ${shadow_inv_outer_md};
			display: block;
			min-width: 200px;
		}
		&:hover,
		&:focus {
			@media (max-width: 1199px) {
				border-color: ${second};
				color: ${main};
				background: ${second};
				&:before {
					background: ${second};
				}
			}
		}
		&:active,
		&:active:focus {
			@media (max-width: 1199px) {
				color: ${main};
				box-shadow: ${shadow_inv_outer_md};
				border-color: ${second};
				background: ${second};
			}
		}
	}
`;

export default Styled;
