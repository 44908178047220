import { useStaticQuery, graphql } from "gatsby";

export const useSiteMetadata = () => {
	const { site } = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						title
						author
						siteUrl
						siteLogo
						description
					}
				}
			}
		`
	);
	return site;
};
