import React from "react";

import Styled from "./styled";
import NavLink from "../NavLink";

const DesktopMenu = ({ navs }) => {
	return (
		<Styled className="d-none d-xl-flex">
			{navs.map((link) => (
				<NavLink key={link.id} link={link} />
			))}
		</Styled>
	);
};

export default DesktopMenu;
