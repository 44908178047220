import React from "react";

import Container from "@Components/ui/Container";
import SectionWrapper, { SectionHeading } from "./styled";

const SectionWithHeading = ({ heading, children }) => (
	<SectionWrapper>
		<Container>
			<SectionHeading>{heading}</SectionHeading>
			{children}
		</Container>
	</SectionWrapper>
);

export default SectionWithHeading;
