import styled from "styled-components";

import theme from "@Theme/theme";

const {
	media: { tablet },
	colors: { gray_900, second },
} = theme;

const Styled = styled.ul`
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	font-weight: 500;
	font-size: 12px;
	margin: 0 -13px 10px -13px;
	@media ${tablet} {
		justify-content: flex-end;
		margin-bottom: 0;
	}
	li {
		padding: 0 13px;
		a {
			color: ${gray_900};
			&:hover {
				color: ${second};
			}
		}
	}
`;

export default Styled;
