import styled from "styled-components";

const Styled = styled.div`
	display: flex;
	min-height: 100%;
	align-items: ${({ align = "start" }) => align};
	flex-direction: ${({ direction = "row" }) => direction};
	justify-content: ${({ justify = "start" }) => justify};
`;

export default Styled;
