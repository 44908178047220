import React from "react";

import AnchorLink from "../AnchorLink";
import ButtonLink from "../ButtonLink";
import ExternalLink from "../ExternalLink";
import InternalLink from "../InternalLink";
import DropdownItem from "../DropdownItem";
import { getIsInternalLink, getLinkKey } from "./utils";
import { useSiteMetadata } from "@Hooks/useSiteMetadata";

const LinkVariant = (props) => {
	const {
		siteMetadata: { siteUrl },
	} = useSiteMetadata();
	const { link = "", value, linkType } = props;
	const isInternalLink = getIsInternalLink(link, siteUrl);
	const linkKey = getLinkKey(isInternalLink);
	const componentType = linkType || linkKey;

	const components = {
		internal: <InternalLink {...props} />,
		external: <ExternalLink {...props} />,
		buttonLink: <ButtonLink {...props} />,
		anchorLink: <AnchorLink {...props} />,
		dropdownItem: <DropdownItem {...props} />,
	};

	if (!link && !value) {
		return null;
	}

	return components[componentType];
};

export default LinkVariant;
