import styled from "styled-components";

import theme from "@Theme/theme";

const {
	media: { wdscreen, lgscreen },
	colors: { second, main, white, gray_100, gray_900 },
	animations: { transition_base },
	shadows: { shadow_inv_inset_md },
	bradius: { bradius_md },
} = theme;

const Styled = styled.div`
	width: 100%;
	@media ${wdscreen} {
		max-width: calc(100% - 220px);
		margin-left: auto;
	}
	.navbar-nav {
		margin-bottom: 30px;
		@media ${wdscreen} {
			flex-wrap: wrap;
			margin: 1px 0 0 0;
			justify-content: flex-end;
		}
		> .d-xl-flex > .dropdown > .dropdown-menu {
			border-top: 1px solid ${white};
			box-shadow: 3px 6px 8px -3px rgb(0 0 0 / 20%);
		}
		.dropdown {
			margin: 4px 0;
			@media ${wdscreen} {
				position: static;
				display: flex;
				align-items: center;
				margin: 0 25px;
			}
			&.show {
				> .dropdown-menu.show {
					@media ${wdscreen} {
						display: flex;
						flex-wrap: wrap;
						visibility: visible;
						opacity: 1;
						height: auto;
					}
				}
				.dropdown-link {
					position: relative;
					&:after {
						content: "";
						position: absolute;
						left: 12px;
						height: 1px;
						bottom: 6px;
						width: calc(100% - 24px);
						background: ${main};
						@media ${wdscreen} {
							content: none;
						}
					}
				}
				.dropdown-toggle {
					&.btn {
						&:after {
							transition: transform ${transition_base};
							opacity: 0;
							transform: translate(-50%, -50%) rotate(0);
						}
					}
				}
			}
			.dropdown-link,
			a {
				color: ${white};
				text-transform: capitalize;
				padding: 5px 12px;
				border-radius: ${bradius_md};
				font-weight: 600;
				@media ${wdscreen} {
					color: ${gray_900};
					padding: 9px 24px 7px;
				}
				&:hover,
				&:focus {
					color: ${main};
				}
				&[aria-current="page"] {
					position: relative;
					color: ${main};
					@media ${wdscreen} {
						color: ${second};
						box-shadow: ${shadow_inv_inset_md};
					}
				}
			}
			.dropdown-toggle {
				&:active:focus {
					box-shadow: none;
				}
			}
			.dropdown {
				margin: 0;
				@media ${wdscreen} {
					padding: 0 10px;
				}
				.dropdown-toggle {
					top: 7px;
					&.btn {
						&:before,
						&:after {
							opacity: 1;
							box-shadow: none;
						}
					}
					&:active:focus {
						box-shadow: none;
					}
				}
				&.show {
					.dropdown-toggle {
						&.btn {
							&:after {
								transition: transform ${transition_base};
								opacity: 0;
								transform: translate(-50%, -50%) rotate(0);
							}
							&:active,
							&:focus,
							&.active,
							&:active:focus,
							&:hover {
								box-shadow: none;
							}
						}
					}
				}
			}
			.dropdown-menu {
				background: none;
				border-radius: 0;
				text-transform: uppercase;
				border: none;
				padding: 21px 0 20px 37px;
				color: ${white};
				@media ${wdscreen} {
					width: 100%;
					position: absolute;
					right: 0;
					left: 0;
					top: 100%;
					color: ${second};
					background: ${gray_100};
					padding: 83px 0;
					opacity: 0;
					height: 0;
					visibility: hidden;
					display: flex !important;
				}
				@media ${lgscreen} {
					padding: 50px 0;
				}
				&:before {
					@media ${wdscreen} {
						content: "";
						position: absolute;
						top: -17px;
						left: 0;
						width: 100%;
						height: 17px;
						background: transparent;
					}
				}
				.container {
					max-width: 100%;
					display: block;
					@media ${wdscreen} {
						display: flex;
						max-width: 1060px;
						align-items: flex-start;
					}
				}
				.dropdown-link,
				a {
					padding: 5px 12px;
					@media ${wdscreen} {
						padding: 5px 0;
						font-weight: 700;
					}
				}
				.dropdown {
					@media ${wdscreen} {
						width: 20%;
						display: block !important;
					}
					&:first-child {
						@media ${wdscreen} {
							width: 18%;
						}
					}
					&:nth-child(2) {
						@media ${wdscreen} {
							width: 22%;
						}
					}
					&:nth-child(3) {
						@media ${wdscreen} {
							width: 19.5%;
						}
					}
				}
				.dropdown-menu {
					padding: 10px 0 18px 38px;
					@media ${wdscreen} {
						padding: 0;
						position: static;
						flex-direction: column;
						align-items: flex-start;
						display: flex !important;
					}
					.dropdown-item,
					.dropdown-link,
					.dropdown .dropdown-menu a {
						text-transform: none;
						font-size: 18px;
						padding: 2px 0;
						margin: 0;
						font-weight: 400;
					}
				}
				.dropdown-item {
					white-space: normal;
					@media ${wdscreen} {
						width: auto;
						display: inline-block;
					}
					&:hover,
					&:focus,
					&:active,
					&.active {
						background: none;
						outline: none;
					}
				}
			}
		}
	}
	.dropdown-holder {
		display: flex;
		position: relative;
		padding-right: 30px;
		@media ${wdscreen} {
			padding-right: 0;
		}
		.dropdown-link {
			padding-left: 30px;
			@media ${wdscreen} {
				padding-left: 0;
			}
		}
		.dropdown-toggle {
			position: absolute;
			right: 0;
			top: 12px;
			&.btn {
				background: none;
				padding: 0;
				margin: 0;
				border: 0;
				height: 20px;
				width: 20px;
				display: inline;
				box-shadow: none;
				@media ${wdscreen} {
					display: none;
				}
				&:before,
				&:after {
					position: absolute;
					border: none;
					background: ${white};
					width: 1px;
					height: 11px;
					opacity: 1;
					border-radius: 0;
					left: 50%;
					top: 50%;
					transform: translate(-50%, -50%);
				}
				&:before {
					transition: transform ${transition_base};
					top: 50%;
					transform: translate(-50%, -50%) rotate(90deg);
				}
				&:after {
					top: 10px;
				}
			}
		}
	}
`;

export default Styled;
