// theme.js

const DEFAULT_SPACE = 21;
const XS_MULTIPLIER = 0.5;
const SM_MULTIPLIER = 1;
const MD_MULTIPLIER = 2;
const LG_MULTIPLIER = 4;
const XL_MULTIPLIER = 6;

// eslint-disable-next-line import/no-anonymous-default-export
export default {
	colors: {
		white: "#fff",
		black: "#000",
		black_100: "rgba(0, 0, 0, 0.2)",
		main: "#FC2550",
		second: "#174667",
		gray_100: "#F1F1F1",
		gray_200: "#F8F8F8",
		gray_250: "#F5F5F5",
		gray_275: "#D5D5D5",
		gray_300: "#CED3DC",
		gray_350: "#A1A1A1",
		gray_500: "#282E3A",
		gray_600: "#7F89A4",
		gray_800: "#4E4E4E",
		gray_900: "#191919",
	},

	gradients: {
		gradient_100: "linear-gradient(102deg, #fff 0%, #f2f2f2 100%) 0% 0%",
		gradient_200: "linear-gradient(102deg, #F8F8F8 0%, #EDEEF2 100%) 0% 0%",
	},

	shadows: {
		shadow_outer_md:
			"-3px -3px 6px 0px #fff, 3px 3px 8px 0px rgba(0, 0, 0, 0.2)",
		shadow_md: "3px 3px 8px 0px rgba(0, 0, 0, 0.25)",
		shadow_inset_md:
			"inset -3px -3px 6px 0px #fff, inset 3px 3px 8px 0px rgba(0, 0, 0, 0.2)",
		shadow_outer_lg:
			"-4px -4px 12px 0px #fff, 4px 4px 12px 0px rgba(0, 0, 0, 0.2)",
		shadow_inset_lg:
			"inset 4px 4px 12px 0px rgba(0, 0, 0, 0.2), inset -4px -4px 12px 0px #fff",
		shadow_inv_outer_md:
			"-3px -3px 6px 0px rgba(255, 255, 255, 0.2), 3px 3px 8px 0px rgba(0, 0, 0, 0.2)",
		shadow_inv_inset_md:
			"inset -3px -3px 6px 0px rgba(255, 255, 255, 0.2), inset 3px 3px 8px 0px rgba(0, 0, 0, 0.2)",
		shadow_outer_second: "-3px -3px 6px #FFFFFF29",
		shadow_outer_dark: "5px 5px 12px #00000029",
	},

	media: {
		phone: "(min-width: 576px)",
		tablet: "(min-width: 768px)",
		desktop: "(min-width: 992px)",
		wdscreen: "(min-width: 1200px)",
		lgscreen: "(min-width: 1400px)",
		xlscreen: "(min-width: 1800px)",
		maxPhone: "(max-width: 767px)",
		maxTablet: "(max-width: 991px)",
		maxDesktop: "(max-width: 1200px)",
		maxExtraLarge: "(max-width: 1899px)",
		maxSmallPhone: "(max-width: 575px)",
	},

	bradius: {
		bradius_sm: "8px",
		bradius_md: "12px",
		bradius_lg: "16px",
		bradius_xlg: "20px",
	},

	animations: {
		transition_base: ".3s ease-in-out",
	},

	BREAKPOINTS: {
		PHONE: "576",
		TABLET: "768",
		DESKTOP: "992",
		WDSCREEN: "1200",
		WDSXLSCREEN: "1900",
	},

	space: {
		default_space: DEFAULT_SPACE,
		space_xs: `${DEFAULT_SPACE * XS_MULTIPLIER}px`,
		space_sm: `${DEFAULT_SPACE * SM_MULTIPLIER}px`,
		space_md: `${DEFAULT_SPACE * MD_MULTIPLIER}px`,
		space_lg: `${DEFAULT_SPACE * LG_MULTIPLIER}px`,
		space_xl: `${DEFAULT_SPACE * XL_MULTIPLIER}px`,
	},

	font_size: {
		h1_desktop: "36px",
		h1_mobile: "28px",
		large_body_desktop: "18px",
		large_body_mobile: "16px",
		body_desktop: "16px",
		body_mobile: "14px",
	},
	font_weight: {
		bold: "700",
		semibold: "600",
		normal: "400",
	},
	font_family: {
		primary:
			"ProximaNova, Roboto, Helvetica Neue, Arial, Noto Sans, Liberation Sans, serif",
	},
	line_height: {
		heading: "1.2",
		body: "1.5",
	},
};
