import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import Styled from "./styled";
import Submenu from "./Submenu";
import Socials from "../Socials";
import Logo from "../Header/Logo";
import Partners from "../Partners";
import FooterMenu from "./FooterMenu";
import { useFooterContent } from "@Hooks/useFooterContent";

const Footer = () => {
	const {
		shortAboutUs,
		leftFooterNavs,
		rightFooterNavs,
		informationLinks,
		partnersImageLinks,
		socials,
	} = useFooterContent();
	return (
		<Styled>
			<Container>
				<Row>
					<Col md={4}>
						<div className="footer-logo">
							<Logo />
						</div>
						<div className="text-holder">
							<p>{shortAboutUs}</p>
						</div>
						<Partners partnersImageLinksList={partnersImageLinks} />
					</Col>
					<Col md={4} lg={5}>
						<FooterMenu menuLists={{ leftFooterNavs, rightFooterNavs }} />
					</Col>
					<Col md={4} lg={3}>
						<div className="footer-info">
							<a href="tel:+12092999832" className="link">
								+1 (209) 299-9832
							</a>
							<a href="mailto:dev@vtlabs.org" className="link">
								dev@vtlabs.org
							</a>
							<address>
								HQ: VT Labs OÜ, Sepapaja 6, Tallinn, 15551, Estonia
								<br />
								<br />
								R&D: Petra Hryhorenka Ave, 38a, Kyiv, 02140, Ukraine
								<br />
								<br />
								BD: 548 Market St, San Francisco, California, 94104-5401
							</address>
							<Socials socials={socials} />
						</div>
					</Col>
				</Row>
				<Row className="d-block d-md-flex footer-copyright">
					<Col xs={12}>
						<div className="line-dashed"></div>
					</Col>
					<Col className="order-md-2">
						<Submenu informationLinks={informationLinks} />
					</Col>
					<Col className="order-md-1">
						&copy; VT Labs. {new Date().getFullYear()}
						{` `}
					</Col>
				</Row>
			</Container>
		</Styled>
	);
};

export default Footer;
