import React from "react";
import { Form } from "react-bootstrap";

const FormControl = (props) => {
	const { feedback } = props;
	// TODO: Move Control Styles to Styled Components;
	return (
		<Form.Group>
			<Form.Control required {...props} />
			{feedback && (
				<Form.Control.Feedback type="invalid">{feedback}</Form.Control.Feedback>
			)}
		</Form.Group>
	);
};

export default FormControl;
