import React, { useContext } from "react";
import { Navbar } from "@Components/ui";

import Styled from "./styled";
import StoreContext from "@Context/StoreContext";

const NavbarToggle = () => {
	const { mobileMenuToggle } = useContext(StoreContext);
	return (
		<Styled>
			<Navbar.Toggle
				aria-controls="basic-navbar-nav"
				onClick={mobileMenuToggle}
			/>
		</Styled>
	);
};

export default NavbarToggle;
