import React, { useContext } from "react";

import Styled from "./styled";
import { Link } from "@Components/ui";
import StoreContext from "@Context/StoreContext";

const ContactsButton = ({ title }) => {
	const { closeMobileMenu } = useContext(StoreContext);
	return (
		<Styled>
			<Link link="contact-us" linkType="buttonLink" onClick={closeMobileMenu}>
				{title}
			</Link>
		</Styled>
	);
};

export default ContactsButton;
