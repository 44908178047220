import React, { useState, useEffect, useContext } from "react";

import Logo from "./Logo";
import Styled from "./styled";
import NavbarNav from "./NavbarNav";
import { Link, Container, Navbar } from "@Components/ui";
import NavbarToggle from "./NavbarToggle";
import ContactsButton from "./ContactsButton";
import StoreContext from "@Context/StoreContext";
import { useWindowUpdates } from "@Utils/globalUtils";

const Header = () => {
	const SCROLL_BREAKPOINT = 100;
	const {
		store: { isOpenMobileMenu },
	} = useContext(StoreContext);
	const [scrolled, setScrolled] = useState(false);
	const { windowScrollY } = useWindowUpdates();

	useEffect(() => {
		setScrolled(windowScrollY > SCROLL_BREAKPOINT);
	}, [windowScrollY, SCROLL_BREAKPOINT]);

	return (
		<Styled data-active={scrolled}>
			<Container>
				<Navbar expand="xl" expanded={isOpenMobileMenu}>
					<Navbar.Brand as={Link} link={"/"}>
						<Logo />
					</Navbar.Brand>
					<NavbarToggle />
					<Navbar.Collapse id="basic-navbar-nav">
						<div className="nav-scroll">
							<NavbarNav />
							<ContactsButton title="Book a Call" />
						</div>
					</Navbar.Collapse>
				</Navbar>
			</Container>
		</Styled>
	);
};

export default Header;
