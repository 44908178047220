import styled from "styled-components";

const Styled = styled.div`
	ul {
		margin: 0 -8px 0 -12px;
		li {
			padding: 10px 8px;
			a {
				color: ${(props) => props.theme.colors.gray_900};
				&:hover {
					color: ${(props) => props.theme.colors.main};
				}
			}
			svg {
				width: 20px;
				height: 20px;
				&:first-child & {
					height: 20px;
				}
			}
		}
	}
`;

export default Styled;
