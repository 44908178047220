import React from "react";

import Logo from "./Logo";
import Styled from "./styled";
import { Link, Container, Navbar } from "@Components/ui";

const Minimal = () => {
	return (
		<Styled>
			<Container>
				<Navbar>
					<Navbar.Brand as={Link} link={"/"}>
						<Logo />
					</Navbar.Brand>
				</Navbar>
			</Container>
		</Styled>
	);
};

export default Minimal;
