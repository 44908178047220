import React from "react";

import { getFormatTextToId } from "@Utils/utils";

const AnchorLink = ({ value, children }) => {
	const formatTextToId = getFormatTextToId(value);

	const handleClick = (e) => {
		e.preventDefault();
		const element = document.getElementById(formatTextToId);
		element.scrollIntoView({
			behavior: "smooth",
		});
	};

	return (
		<a onClick={handleClick} href={`#${formatTextToId}`}>
			{children}
		</a>
	);
};

export default AnchorLink;
