import React, { useState } from "react";

import Context from "../context/StoreContext";

const ContextProvider = ({ children }) => {
	const initialState = {
		isOpenMobileMenu: false,
	};

	const [store, updateStore] = useState(initialState);
	const { isOpenMobileMenu } = store;

	return (
		<Context.Provider
			value={{
				store,
				mobileMenuToggle: () =>
					updateStore({ ...store, isOpenMobileMenu: !isOpenMobileMenu }),
				closeMobileMenu: () =>
					updateStore({ ...store, isOpenMobileMenu: false }),
			}}
		>
			{children}
		</Context.Provider>
	);
};

export default ContextProvider;
