import React from "react";

import { Link } from "@Components/ui";

const PartnerList = ({ partnersImageLinksList }) =>
	partnersImageLinksList?.map(
		({ title, id, linksList: [{ linkUrl }], mediaEntry }) => {
			if (!mediaEntry) {
				return null;
			}
			return (
				<li key={id}>
					<Link link={linkUrl}>
						<img src={mediaEntry.fluid?.src} alt={title} />
					</Link>
				</li>
			);
		}
	);

export default PartnerList;
