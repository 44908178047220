import styled from "styled-components";

import theme from "@Theme/theme";

const {
	media: { tablet, wdscreen, phone },
	colors: { second, gray_100, white },
	animations: { transition_base },
	shadows: { shadow_header },
} = theme;

const Styled = styled.div`
	position: fixed;
	width: 100%;
	top: 0;
	left: 0;
	right: 0;
	min-height: 80px;
	z-index: 999;
	background: ${gray_100};
	box-shadow: ${shadow_header};
	border-bottom: 1px solid ${white};
	transition: background ${transition_base};
	display: flex;
	align-items: center;
	@media ${wdscreen} {
		min-height: 80px;
	}
	.container {
		@media ${tablet} {
			max-width: 1380px;
		}
		@media ${phone} {
			max-width: none;
		}
	}
	.navbar {
		background: none;
		border-radius: 12px;
		font-size: 22px;
		padding: 12px 22px;
		position: static;
		@media ${wdscreen} {
			padding: 0 25px;
			font-size: 18px;
		}
		.navbar-collapse {
			background: ${second};
			position: fixed;
			top: 0;
			right: 0;
			padding-top: 94px;
			width: 100vw;
			height: 100vh;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-items: flex-start;
			transform: translate3d(100vw, 0, 0);
			transition: transform 0.5s cubic-bezier(0, 0.52, 0, 1);
			@media ${wdscreen} {
				position: static;
				background: none;
				height: auto;
				padding: 0;
				transform: none;
			}
			&.show {
				transform: translate3d(0vw, 0, 0);
			}
		}
	}
	.nav-scroll {
		width: 100%;
		max-width: 540px;
		margin: 0 auto;
		overflow-y: auto;
		min-height: 100%;
		padding: 0 45px 100px 63px;
		@media ${tablet} {
			max-width: 1380px;
		}
		@media ${wdscreen} {
			max-width: none;
			padding: 0;
			display: flex;
			align-items: center;
			justify-content: flex-end;
			margin: 0;
			overflow: visible;
		}
	}
`;

export default Styled;
