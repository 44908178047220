import styled from "styled-components";

import theme from "@Theme/theme";

const {
	media: { tablet },
	colors: { gray_900 },
	font_family: { primary },
	line_height: { body },
	font_size: { body_desktop, body_mobile },
} = theme;

const Text = styled.div`
	font-family: ${primary};
	line-height: ${body};
	font-size: ${body_mobile};
	color: ${gray_900};
	@media ${tablet} {
		font-size: ${body_desktop};
	}
`;

export default Text;
