import React from "react";
import { Link } from "gatsby";

import { getFormattedURL } from "@Utils/utils";
import { useSiteMetadata } from "@Hooks/useSiteMetadata";

const InternalLink = ({
	rel,
	onClick,
	children,
	link = "",
	className = "",
}) => {
	const {
		siteMetadata: { siteUrl },
	} = useSiteMetadata();
	const formattedUrl = getFormattedURL(link).replace(siteUrl + "/", "");
	return (
		<Link
			rel={rel}
			onClick={onClick}
			className={className}
			to={`/${formattedUrl}`}
		>
			{children}
		</Link>
	);
};

export default InternalLink;
