import React from "react";

import { getFormattedExternalLink } from "@Utils/utils";

const ExternalLink = ({ children, link, className, onClick }) => {
	const formattedLink = getFormattedExternalLink(link);
	return (
		<a
			target="_blank"
			onClick={onClick}
			href={formattedLink}
			className={className}
			rel="noopener noreferrer"
		>
			{children}
		</a>
	);
};

export default ExternalLink;
