import React from "react";
import { Column, Row } from "@Components/ui";

import Item from "./Item";

const Circles = ({ itemsList }) => {
	if (!itemsList || itemsList.length === 0) {
		return null;
	}

	return (
		<Row>
			{itemsList.map(({ title, text, imageUrl, imageAlt }, index) => (
				<Column key={index} lg={4}>
					<Item
						title={title}
						text={text}
						imageUrl={imageUrl}
						imageAlt={imageAlt}
					/>
				</Column>
			))}
		</Row>
	);
};

export default Circles;
