import React from "react";

import Styled from "./styled";
import PartnerList from "./PartnerList";

const Partners = ({ partnersImageLinksList }) => (
	<Styled className="list-unstyled">
		<PartnerList partnersImageLinksList={partnersImageLinksList} />
	</Styled>
);

export default Partners;
