import React from "react";
import { Nav } from "react-bootstrap";

import Styled from "./styled";
import NavItem from "./NavItem";

const FooterMenu = ({ menuLists }) => {
	const { leftFooterNavs, rightFooterNavs } = menuLists;
	return (
		<Styled>
			<div className="d-none d-md-block d-lg-flex">
				<Nav>
					{leftFooterNavs.length &&
						leftFooterNavs.map(({ id, navName, navLink }) => (
							<NavItem key={id} navItemData={{ navName, navLink }} />
						))}
				</Nav>
				<Nav>
					{rightFooterNavs.length &&
						rightFooterNavs.map(
							({ id, groupName: navName, groupLink: navLink }) => (
								<NavItem key={id} navItemData={{ navName, navLink }} />
							)
						)}
				</Nav>
			</div>
		</Styled>
	);
};

export default FooterMenu;
