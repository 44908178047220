import { camelCase } from "lodash";
import { mapSliceNames } from "@Utils/Contentful/utils";
import { getRequiredSlice } from "@Utils/Contentful/SingleSlice/utils";

export const getFilteredCards = (cards, filterKey) => {
	const filterCards = (card) =>
		filterKey === "ALL"
			? card
			: card.directionTag?.toUpperCase().includes(filterKey);

	return cards ? cards.filter(filterCards) : [];
};

export const getSlice = (slices, returnSliceId) =>
	getRequiredSlice(slices, mapSliceNames(returnSliceId));

export const getSliceZoneData = (slices, returnSliceId) =>
	slices?.filter(({ sliceType }) => sliceType === returnSliceId);

export const getFormattedDate = (createdAt) =>
	new Date(createdAt).toLocaleDateString("en-US", {
		month: "long",
		day: "numeric",
		year: "numeric",
	});

export const getJsonFromString = (string) =>
	string ? JSON.parse(string) : null;

export const getFormattedURL = (url) =>
	url
		?.trim()
		.toLowerCase()
		.replace(/ /g, "-")
		.replace(/^\/|\/$/g, "");

export const getFormatTextToId = (text) => camelCase(text);

export const getFormattedExternalLink = (link) => {
	const isIncludeHttp = link.includes("http");
	return isIncludeHttp ? link : "https://" + link;
};

export const getRandomArbitrary = (arrayLength) => {
	const casesToShow = "3";
	const max = arrayLength - +casesToShow;
	const min = 0;
	const random = Math.floor(Math.random() * (max - min) + min);
	return { startSlice: random, endSlice: random + +casesToShow };
};

export const getRandomList = (beforeItems, afterItems) => {
	const MIN_NUMBER_OF_ITEMS = 3;
	return afterItems.length === MIN_NUMBER_OF_ITEMS ? afterItems : beforeItems;
};

export const getListToRender = (relatedItemsList, randomItemsList) => {
	const MIN_NUMBER_OF_ITEMS = 3;
	if (relatedItemsList.length > MIN_NUMBER_OF_ITEMS) {
		const { startSlice, endSlice } = getRandomArbitrary(
			relatedItemsList.length
		);
		return relatedItemsList.slice(startSlice, endSlice);
	}

	return relatedItemsList.length ? relatedItemsList : randomItemsList;
};

export const getFormattedBreadcrumb = (url) =>
	url?.trim().toLowerCase().replace(/-/g, " ");

export const ifRichTextEmpty = (data) =>
	data !== null && data?.content[0]?.content[0]?.value !== "";

export const getCapitalizeString = (word) =>
	word[0].toUpperCase() + word.substring(1).toLowerCase();

export const getMinToRead = (string) => {
	const CHARACTERS_READ_PER_MINUTE = 1500;
	return Math.ceil(string.length / CHARACTERS_READ_PER_MINUTE);
};

export const mapImageToModelData = (image) => ({
	id: image.id,
	src: image,
	alt: image.title,
});
