import React from "react";
import { Nav } from "react-bootstrap";

import DesktopMenu from "./DesktopMenu";
import MobileMenu from "./MobileMenu";
import { useTopMenu } from "@Hooks/useTopMenu";

import Styled from "./styled";

const NavbarNav = () => {
	const { navs } = useTopMenu();

	return (
		<Styled>
			<Nav>
				<DesktopMenu navs={navs} />
				<MobileMenu navs={navs} />
			</Nav>
		</Styled>
	);
};

export default NavbarNav;
