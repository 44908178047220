import styled from "styled-components";

import theme from "@Theme/theme";

const {
	colors: { white },
	bradius: { bradius_lg },
	space: { space_sm, space_md },
} = theme;

const WideCard = styled.div`
	padding: ${space_sm} ${space_md};
	border-radius: ${bradius_lg};
	border: 1px solid ${white};
`;

export { WideCard };
