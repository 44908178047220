import React from "react";
import { isEmpty } from "lodash";

import Styled from "./styled";
import { Link } from "@Components/ui";

const Submenu = ({ informationLinks }) => {
	if (isEmpty(informationLinks)) {
		return null;
	}

	return (
		<Styled className="list-unstyled">
			{informationLinks.map(({ id, linkName, linkUrl }) => (
				<li key={id}>
					<Link link={linkUrl}>{linkName}</Link>
				</li>
			))}
		</Styled>
	);
};

export default Submenu;
