import { useStaticQuery, graphql } from "gatsby";

export const useFooterContent = () => {
	const { contentfulFooterMenu } = useStaticQuery(
		graphql`
			query {
				contentfulFooterMenu {
					title
					shortAboutUs
					leftFooterNavs {
						navLink
						navName
						id
					}
					rightFooterNavs {
						groupName
						groupLink
						id
					}
					informationLinks {
						linkName
						linkUrl
						id
					}
					partnersImageLinks {
						title
						id
						linksList {
							linkName
							linkUrl
						}
					}
					socials {
						linkUrl
						linkName
						id
					}
				}
			}
		`
	);

	return contentfulFooterMenu;
};
