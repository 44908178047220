import React from "react";
import { isEmpty } from "lodash";
import { GoQuote } from "react-icons/go";
import { INLINES, BLOCKS, MARKS } from "@contentful/rich-text-types";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

import Video from "@Components/Video";
import { Link, Image, H1 } from "@Components/ui";
import { getFormatTextToId, getJsonFromString } from "../utils";
import { StyledOl, StyledUl, StyledPre, StyledColoredText } from "./styled";

export const getLineEntry = (requiredID, references = []) =>
	references?.find(({ contentful_id }) => contentful_id === requiredID);

export const contentReducer = (map, references) => (
	_,
	{ data: { target } }
) => {
	if (typeof target !== "undefined") {
		const targetID = target.sys.id;
		const inlineEntry = getLineEntry(targetID, references);
		return typeof inlineEntry !== "undefined"
			? map.set(inlineEntry.contentful_id, inlineEntry)
			: null;
	}
	return null;
};

export const assetBlockMap = (optionsContent, references) =>
	optionsContent?.reduce((map, { content }) => {
		!isEmpty(content) && content.reduce(contentReducer(map, references));
		return map;
	}, new Map());

export const getRenderOptions = (contentData, references) => {
	const { content } = contentData;
	const assetBlock = assetBlockMap(content, references);

	return {
		renderNode: {
			[INLINES.EMBEDDED_ENTRY]: function renderColoredText(node) {
				const asset = assetBlock.get(node.data.target.sys.id);
				const { textColor, fontSize, textAlign, coloredText } = asset || {};
				const coloredTextJson = getJsonFromString(coloredText?.raw);
				const renderTextOptions = getRenderTitleOptions(
					coloredTextJson,
					references
				);
				if (!asset) {
					return null;
				}
				return (
					<StyledColoredText
						fontSize={fontSize}
						textColor={textColor}
						textAlign={textAlign}
					>
						{documentToReactComponents(coloredTextJson, renderTextOptions)}
					</StyledColoredText>
				);
			},
			[INLINES.HYPERLINK]: function renderLink(node) {
				const {
					content: [{ value }],
					data: { uri: url },
				} = node;
				return <Link link={url}>{value}</Link>;
			},
			[INLINES.ENTRY_HYPERLINK]: function entryHyperLink(node) {
				const asset = assetBlock.get(node.data.target.sys.id);
				return <Video data={asset} />;
			},
			[BLOCKS.EMBEDDED_ENTRY]: function renderBlockEmBeddedEntry(node) {
				const blockEntry = getLineEntry(node.data.target.sys.id, references);
				if (blockEntry?.sys.contentType.sys.id === "imageWithLink") {
					const {
						link,
						image: { title, description, gatsbyImageData },
					} = blockEntry;
					const { isShowDescriptionBelowImages } = contentData;

					return (
						<Link link={link}>
							<Image
								className="rounded"
								alt={title}
								src={gatsbyImageData}
								description={description}
								isShowDescription={isShowDescriptionBelowImages}
							/>
						</Link>
					);
				}
			},
			[BLOCKS.QUOTE]: function renderBlockquoteText(_, children) {
				return (
					<blockquote className="blockquote">
						<GoQuote className="icon" />
						{children}
					</blockquote>
				);
			},
			[BLOCKS.HEADING_2]: function renderHeading2(_, children) {
				const id = getFormatTextToId(
					children[0].props?.children || children[0]
				);
				return (
					<h2 className="h3" id={id}>
						{children}
					</h2>
				);
			},
			[BLOCKS.OL_LIST]: function renderBlockOlList(_, children) {
				return <StyledOl>{children}</StyledOl>;
			},
			[BLOCKS.UL_LIST]: function renderBlockOlList(_, children) {
				return <StyledUl>{children}</StyledUl>;
			},
			[BLOCKS.EMBEDDED_ASSET]: function renderImage(node) {
				const targetID = node.data.target.sys.id;
				const { isShowDescriptionBelowImages } = contentData;
				const inlineEntry = getLineEntry(targetID, references);
				const { title, description, gatsbyImageData } = inlineEntry || {};
				return (
					<Image
						alt={title}
						className="rounded"
						src={gatsbyImageData}
						description={description}
						isShowDescription={isShowDescriptionBelowImages}
					/>
				);
			},
			[BLOCKS.PARAGRAPH]: function renderParagraph(_, children) {
				const [line] = children.filter(Boolean);
				if (isEmpty(line)) {
					return;
				}
				return <p>{children}</p>;
			},
		},
		renderMark: {
			[MARKS.CODE]: function renderCode(node) {
				return (
					<StyledPre>
						<code>{node}</code>
					</StyledPre>
				);
			},
			[MARKS.BOLD]: function renderBold(node) {
				return <b>{node}</b>;
			},
			[MARKS.ITALIC]: function renderItalic(node) {
				return <i>{node}</i>;
			},
			[MARKS.UNDERLINE]: function renderUnderline(node) {
				return <u>{node}</u>;
			},
		},
	};
};

export const getUnStyledOptions = (mainJson, references) => {
	const assetBlock = assetBlockMap(mainJson?.content, references);

	return {
		renderNode: {
			[INLINES.EMBEDDED_ENTRY]: function renderColoredText(node) {
				const asset = assetBlock.get(node.data.target.sys.id);
				const coloredTextJson = getJsonFromString(asset.coloredText.raw);
				return <span>{documentToReactComponents(coloredTextJson)}</span>;
			},
			[BLOCKS.HEADING_2]: function renderHeading2() {
				return null;
			},
			[BLOCKS.OL_LIST]: function renderBlockOlList() {
				return null;
			},
			[BLOCKS.UL_LIST]: function renderBlockOlList() {
				return null;
			},
			[BLOCKS.QUOTE]: function renderBlockquoteText() {
				return null;
			},
		},
		renderMark: {
			[MARKS.CODE]: function renderCode() {
				return null;
			},
		},
	};
};

export const getRenderTitleOptions = (data, references) => {
	const renderOptions = getRenderOptions(data, references);
	return {
		...renderOptions,
		renderNode: {
			...renderOptions.renderNode,
			[BLOCKS.HEADING_2]: function renderHeading2(_, children) {
				return <h2 className="h2">{children}</h2>;
			},
			[BLOCKS.HEADING_3]: function renderHeading3(_, children) {
				return <h3 className="h3">{children}</h3>;
			},
			[BLOCKS.HEADING_4]: function renderHeading4(_, children) {
				return <h4 className="h4">{children}</h4>;
			},
			[BLOCKS.PARAGRAPH]: function renderParagraph(_, children) {
				const [line] = children.filter(Boolean);
				if (isEmpty(line)) {
					return;
				}
				return children;
			},
		},
	};
};

export const getRenderFullOptions = (data, references) => {
	const renderOptions = getRenderOptions(data, references);
	return {
		...renderOptions,
		renderNode: {
			...renderOptions.renderNode,
			[BLOCKS.HEADING_1]: function renderHeading2(_, children) {
				return <H1>{children}</H1>;
			},
		},
	};
};

export const getRenderAnchorHeadersOptions = () => {
	return {
		renderNode: {
			[BLOCKS.HEADING_2]: function renderHeading2(node, children) {
				return (
					<li>
						<Link linkType="anchorLink" value={node.content[0].value}>
							{children}
						</Link>
					</li>
				);
			},
			[BLOCKS.HEADING_1]: function renderHeading1() {
				return null;
			},
			[BLOCKS.HEADING_3]: function renderHeading3() {
				return null;
			},
			[BLOCKS.HEADING_4]: function renderHeading4() {
				return null;
			},
			[BLOCKS.PARAGRAPH]: function renderParagraph() {
				return null;
			},
		},
	};
};

export const mapSliceNames = (sliceName) => {
	const sliceNamesMapper = {
		headingAndTextBelow: "headingAndTextBellow",
	};

	return sliceNamesMapper[sliceName] || sliceName;
};
