import React from "react";
import { RiFacebookFill } from "react-icons/ri";
import { ImTwitter } from "react-icons/im";
import {
	AiFillLinkedin,
	AiFillInstagram,
	AiOutlineInstagram,
	AiOutlineLink,
} from "react-icons/ai";
import { SiUpwork } from "react-icons/si";

const SocialIcon = ({ name }) => {
	const socialIcon = {
		Facebook: <RiFacebookFill />,
		Twitter: <ImTwitter />,
		Linkedin: <AiFillLinkedin />,
		Upwork: <SiUpwork />,
		Instagram: <AiFillInstagram />,
		InstagramOutline: <AiOutlineInstagram />,
		OutlineLink: <AiOutlineLink />,
	};

	if (!socialIcon?.[name]) {
		return null;
	}
	return socialIcon[name];
};

export default SocialIcon;
