import React from "react";
import { ThemeProvider } from "styled-components";
import Header from "@Components/Header/Minimal";

import ContextProvider from "../provider/ContextProvider";
import Theme from "../theme/theme";
import GlobalStyles from "../theme/global";

const ContactUs = ({ children }) => (
	<ContextProvider>
		<ThemeProvider theme={Theme}>
			<GlobalStyles />
			<Header />
			<div>{children}</div>
		</ThemeProvider>
	</ContextProvider>
);

export default ContactUs;
