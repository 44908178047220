import styled from "styled-components";

import theme from "@Theme/theme";

const {
	media: { tablet, desktop },
	colors: { main, gray_300, gray_900, second },
} = theme;

const Styled = styled.div`
	overflow: hidden;
	padding: 50px 0 50px;
	@media ${tablet} {
		padding: 70px 0 50px;
	}
	@media ${desktop} {
		padding: 100px 0 50px;
	}
	.container {
		padding-top: 64px;
		position: relative;

		&:before {
			content: "";
			position: absolute;
			top: 0;
			left: 15px;
			width: calc(100% - 30px);
			height: 1px;
			background-color: ${gray_300};
		}
		@media ${tablet} {
			padding-top: 140px;
		}
	}

	.footer-logo img,
	.footer-logo a {
		max-width: 113px;
	}

	.text-holder {
		font-size: 14px;
		line-height: 1.45;
		padding-top: 15px;
		margin-bottom: 36px;
		@media ${tablet} {
			margin-bottom: 50px;
		}
		@media ${desktop} {
			max-width: 243px;
			margin-bottom: 50px;
		}
	}

	.footer-info {
		font-size: 16px;
		line-height: 1.5;
		height: 110%;
		display: grid;
		:last-child {
			align-self: end;
		}
		@media ${tablet} {
			padding-left: 55px;
			padding-top: 10px;
		}
		.link {
			font-size: 16px;
			color: ${second};
			font-weight: 600;
			display: inline-block;
			&:hover {
				color: ${main};
			}
		}
		address {
			padding-top: 6px;
			margin-bottom: 44px;
			@media ${tablet} {
				margin-bottom: 50px;
			}
		}
	}

	.footer-copyright {
		padding-top: 38px;
		font-size: 10px;
		text-align: center;
		@media ${tablet} {
			padding-top: 12px;
			text-align: left;
			font-size: 12px;
		}
		a {
			color: ${gray_900};
			&:hover {
				color: ${second};
			}
		}
		.line-dashed {
			padding-bottom: 33px;
			margin: 13px -50% 0;
			width: 200%;
			@media ${tablet} {
				margin-top: 68px;
				padding-bottom: 24px;
				width: auto;
			}
		}
	}
`;

export default Styled;
