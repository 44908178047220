import React from "react";

import Styled, { Video, Overlay } from "./styled";
import Background from "@Images/bg-hero-video.mp4";

const HeroVideo = () => (
	<Styled>
		<Video
			autoPlay="autoplay"
			muted="muted"
			loop
			data-cy="hero-video"
			style={{ visibility: "hidden" }}
		>
			<source src={Background} type="video/mp4" />
		</Video>
		<Overlay />
	</Styled>
);

export default HeroVideo;
