import styled from "styled-components";

export const StyledVideoWrapper = styled.div`
	width: 100%;
	height: 45vw;
	margin: 60px auto;
	iframe {
		width: 100%;
		height: 100%;
	}
`;
