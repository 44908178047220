import styled from "styled-components";

import theme from "@Theme/theme";

const {
	media: { tablet },
	colors: { gray_900 },
	font_size: { large_body_desktop, large_body_mobile },
	font_family: { primary },
	line_height: { body },
} = theme;

const LeadText = styled.div`
	font-family: ${primary};
	line-height: ${body};
	font-size: ${large_body_mobile};
	color: ${gray_900};
	@media ${tablet} {
		font-size: ${large_body_desktop};
	}
`;

export default LeadText;
