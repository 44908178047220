import React from "react";

import SocialIcon from "./Icon";

const SocialItem = ({ socialItem: { linkName, linkUrl } }) => (
	<li>
		<a href={linkUrl} target="_blank" rel="noopener noreferrer nofollow">
			<SocialIcon name={linkName} />
		</a>
	</li>
);

export default SocialItem;
