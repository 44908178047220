export const getRequiredSlice = (slices, renderSlice) =>
	slices?.filter(
		(slice) => slice.sys && slice.sys.contentType.sys.id === renderSlice
	);

export const getExactSliceFromList = (slicesList, sliceNumberToRender) => {
	const availableSliceToRender =
		slicesList && slicesList.length - 1 >= sliceNumberToRender;
	return sliceNumberToRender && availableSliceToRender
		? slicesList[sliceNumberToRender]
		: slicesList[0];
};
